// Dependencies.
import React, {useLayoutEffect, useState} from 'react'
import {Article as ArticleInterface} from '../types'
import styled from 'styled-components'
import {px, H1ArticleTitle, ContentBlockText, WikiLink} from './Text'
import BackButtonURL from '../static/images/back-icon.svg'
import HideShowIconURL from '../static/images/hs-icon.svg'
import {Link} from 'react-router-dom'
import {scrollToElementWithID} from '../utils'
// Props.
interface Props extends ArticleInterface {
  currentArticleID: string
  shouldBeOpen: boolean
}
interface SubProps extends Props {
  isExpandedStory: boolean,
  setIsExpandedStory: React.Dispatch<React.SetStateAction<boolean>>
}

const HideShowBlock: React.FC<SubProps> = props => {
  const { isExpandedStory, body, wikipediaURL, setIsExpandedStory} = props
  return (
    <HideShow
      className={isExpandedStory ? 'expanded' : 'un-expanded' }
    >
      <ContentBlock dangerouslySetInnerHTML={toHTML(body)} />
      {/* the hide show menu and wiki link */}
      <HideShowMenu>
        <HideShowButton
          onClick={()=> setIsExpandedStory(!isExpandedStory)}
        >
          Read {(isExpandedStory ? 'less': 'more')}
        </HideShowButton>
        <WikiLinkButton
          style={{
            display: ((wikipediaURL.length > 0 ) ? 'inline' : 'none')
          }}
          href={wikipediaURL}
          target={"_blank"}
        />
      </HideShowMenu>
    </HideShow>
  )
}

// Article.
export const Article: React.FC<Props> = props => {

  // Props.
  const {header, href, currentArticleID, imageURL, body, twitter, wikipediaURL} = props

  // State
  const [isOpen, setIsOpen] = useState(props.shouldBeOpen)
  const [isExpandedStory, setIsExpandedStory] = useState(false)

  // mobile hide show scroll bar
  if (isOpen) {
    document.body.classList.add('m-body-no-scroll')
  } else {
    document.body.classList.remove('m-body-no-scroll')
  }

  // On Change - Pathname.
  useLayoutEffect(() => {
    currentArticleID && scrollToElementWithID(currentArticleID)
  }, [currentArticleID])
  // ..
  return <Container>
    {/* Image Block. */}
    <Link
      className={'image-block-link'}
      style={{
        textDecoration: 'none',
      }}
      to={`/story/${href}`}
    >
      <ImageBlock
        onClick={()=> {
          scrollToElementWithID(href)
          setTimeout(() => { setIsOpen(!isOpen) }, 500);
        }}
        id={href}
      >
        <Heading>{header}</Heading>
        {/* <FadeInOnParentHover>
          <OpenButton>Read Story</OpenButton>
        </FadeInOnParentHover> */}
        <ImageBlockBackground src={imageURL} />
      </ImageBlock>
    </Link>
    {/* the story wrapper and modal */}
    <ShadowWrapper_Modal className={'no-scrollbar'}>
      {/* mobile navigation */}
      <ModalNav>
        <BackButton
          onClick={()=> { setIsOpen(false) }}
        >
          <BackButtonIcon src={BackButtonURL}/>
        </BackButton>
      </ModalNav>
      {/* scroll & container */}
      <ContentContainer
        className={isOpen ? 'open no-scrollbar ' : 'close no-scrollbar' }
      >
        {/* mobile bacground and title */}
        <MobileHero
          style={{backgroundImage: `url(${imageURL})`}}
        >
          <h3>{header}</h3>
        </MobileHero>
        {/* blerb hide/show block */}
        <HideShowBlock {...props} setIsExpandedStory={setIsExpandedStory} isExpandedStory={isExpandedStory}/>
        {/* twotter block */}
        <ContentBlock dangerouslySetInnerHTML={toHTML(twitter)} />
      </ContentContainer>
    </ShadowWrapper_Modal>
  </Container>
}

const WikiLinkButton = styled(WikiLink)`
  pointer-events: all;`
//  the hide show button for triggering reveal of blerb
const HideShowButton = styled.button`
    pointer-events: all;
    position: relative;
    height: 40px;
    margin: 0;
    padding: 0;
    border: none;
    outline: 0;
    font-size: ${px(14)};
    line-height: ${px(14)};
    color: #3B72A5;
    background-color: transparent;
    cursor: pointer;
    margin-bottom: -5px;
    font-family: 'BentonSans', sans-serif;
    font-weight: 500;

    @media (max-width: 500px) {
      margin-bottom: unset;
    }

    &:after {
      transition: transform 0.4s;
      transform: rotate(0deg);
      position: absolute;
      content: ' ';
      width: 20px;
      height: 100%;
      top: 0px;
      right: -25px;

      background: url(${HideShowIconURL});
      background-position: center;
      background-repeat: no-repeat;
    }
`

// the hide show menu container - also contains the wiki button
const HideShowMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  pointer-events: none;
  position: absolute;
  bottom: 0px;
  width: calc(640px - 100px);
  padding-left: 50px;
  padding-right: 50px;
  height: 140px;
  background: linear-gradient(0deg,rgba(255,255,255,1) 0%,rgba(255,255,255,1) 44px,rgba(255,255,255,0) 100%);

  @media (max-width: 500px) {
    background: linear-gradient(0deg,rgba(0,0,0,1) 0%,rgba(0,0,0,1) 44px,rgba(0,0,0,0) 100%);
    width: calc(100% - 40px);
    padding-left: 20px;
    padding-right: 20px;
  }
`
// hide show container for blerb
const HideShow = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
  transition-property: height;
  transition-duration: 0.5s;
`

// the container for the mobile background and title
const MobileHero = styled.div`
  display: none;
  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: calc(100vw - 40px);
    height: 80vh;
    /* background: red; */
    background-size: cover;
    padding-left: 20px;
    padding-right: 20px;

    h3 {
      font-family: 'BentonSans', sans-serif;
      font-weight: bold;
      font-size: ${px(24)};
      line-height: ${px(28.8)};
      margin-bottom: 15px;
    }
  }
`

// see ImageBlock:hover for css
const FadeInOnParentHover = styled.span``

// main container
const Container = styled.div`
  display: flex;
  flex-shrink: 0;
  .close {
    width: 0px;
    @media (max-width: 500px) {
      width: 0vw;
    }
  }
  .open {
    width: 640px;
    /* display: flex; */
    overflow-y: scroll;
    overflow-x: visible;
    height: 100vh;
    @media (max-width: 500px) {
      overflow-x: hidden;
      width: 100vw;
    }
  }

  .expanded {
    height: auto;
    max-height: none;
    padding-bottom: 20px;
    @media (max-width: 500px) {
      padding-bottom: 34px;
    }
    ${HideShowMenu} {
      background: unset;
    }
    ${HideShowButton}:after {
      transform: rotate(180deg);
    }
  }

  .image-block-link {
    width: fit-content;
    @media (max-width: 500px) {
      width: 100%;
    }
  }

`

// Wrapper for the shadow and acts as a modal on mobile
const ShadowWrapper_Modal = styled.div`
  position: relative;
  /* overflow-x: hidden; */

  @media (max-width: 500px) {
    position: fixed;
    z-index: 999;
    top: 0px;
    bottom: 0px;
    right: 0px;
    color: white;
    background: black;
    /* overflow: hidden; */
  }
`
// the container for mobile navigation
const ModalNav =  styled.div`
  display: none;

  @media (max-width: 500px) {
    display: flex;
    justify-content: space-between;

    position: absolute;
    z-index: 902;
    color: #FFF;
    height: 48px;
    width: 100vw;
    font-size: 14px;
    top: 0;
    left: 0;

    background: linear-gradient(166.9deg,rgba(104, 134, 178, 0.95) 0% , rgba(190, 227, 221, 0.95) 100%);
  }

`


// the back button in mobile
const BackButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 54px;
  height: 48px;
  cursor: pointer;
`
const BackButtonIcon = styled.img`
  cursor: pointer;
`

// the bacground image for the story - background
const ImageBlockBackground = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: -1;

  transition-property: width, height;
  transition-duration: 0.4s;

  @media (max-width: 500px) {
    margin-left: -20px; /* fix the padding from moving the image over */
  }

`

// image left block
const ImageBlock = styled.div`
  overflow: hidden;
  position: relative;

  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 640px;
  height: 100vh;
  color: white;
  cursor: pointer;

  @media (max-width: 500px) {
    align-items: start;
    width: calc(100% - 40px);
    height: fit-content;
    padding: 60px 20px;

  }

  ${FadeInOnParentHover} {
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.4s;
    @media (max-width: 500px) {
      opacity: 1; /* show on mobile */
    }
  }
  &:hover {
    background-size: auto 101%;
    @media (max-width: 500px) {
      background-size: auto 100%;
    }

    ${FadeInOnParentHover} {
      opacity: 1;
    }
    ${ImageBlockBackground} {
      width: calc(100% + 20px);
      height: calc(100% + 20px);
    }
  }
`

// Image heading
const Heading = styled(H1ArticleTitle)`
  width: 500px;
  text-align: center;

  @media (max-width: 500px) {
    width: 100%;
    max-width: 200px;
    margin-bottom: 12px;
    text-align: left;
  }
`

// Content Container - with is controlled in Container using .open and .close
const ContentContainer = styled.div`
  transition: width .8s cubic-bezier(.19,1,.22,1);
  height: fit-content;
  background-size: 100%;
  background-repeat: no-repeat;
  overflow: hidden;

  @media (min-width: 500px) {
    background-image: unset !important;
  }

  &:before {
    content: "";
    position: absolute;
    top: -100px;
    bottom: -100px;
    left: 0;
    right: 0;
    z-index: 1;
    pointer-events: none;
    box-shadow: 0 8px 16px rgba(0,0,0,.5) inset;
  }
`

// the text container
const ContentBlock = styled(ContentBlockText)`
  width: calc(640px - 100px);
  margin: 50px;
  @media (max-width: 500px) {
    margin: 20px;
    width: calc(100vw - 40px);
  }
`

// convert to HTML obj for inner html

const toHTML = function (body: String) {
  // return {'__html': `<h3 class="mobile-heading">${header}</h3>${body}`}
  return {'__html': `${body}`}
}
