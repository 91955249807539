// Dependencies.
import React from 'react'

// CSS.
import './App.css'

// Components.
import {Router} from './Router'

// App.
const Application: React.FC = () => {
  main()
  // ..
  return <div className="Application">
    <Router/>
  </div>

}

// Export.
export default Application


const main = () => {
  /** Init on Scroll event */
  let wheel: string = ''
  if (window.onwheel !== undefined) { wheel = 'wheel' }
  if (window.onmousewheel !== undefined) { wheel = 'mousewheel' }
  if (wheel === '') { return }
  document.addEventListener(wheel, horizontalScrollUpdate)
}

/* on scroll tick */
const horizontalScrollUpdate = (e: any) => {
  // get all pos data
  let horizontalTarget: any = e.target;
  let horizontalDelta: number = e.wheelDeltaY
  let oldPos: number = window.pageXOffset || document.documentElement.scrollLeft
  let newPos: number = oldPos -= horizontalDelta
  // get details about parent so we can stop scrolling on a story that is open
  let parentA = horizontalTarget.parentElement
  let parentB = horizontalTarget.parentElement.parentElement
  let parentC = horizontalTarget.parentElement.parentElement.parentElement
  let parentD = horizontalTarget.parentElement.parentElement.parentElement.parentElement
  // in the case not on a story scroll body l/r on u/d
  if (!(
      parentA.classList.contains('open') ||
      parentB.classList.contains('open') ||
      parentC.classList.contains('open') ||
      parentD.classList.contains('open')
    ) && (document.documentElement.clientWidth > 500)) {
    document.documentElement.scrollTo(newPos, 0)
  }
}
