// Dependencies.
import {Article} from '../types'

// Stories.
export const articles: {[key: string]: Article} = {
  // Article 1.
  article_1: {
    key:'1',
    href:'metoo-movement',
    header: ' #MeToo Movement ',
    subheader: 'Etc ...',
    twitter: `
      <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">If you’ve been sexually harassed or assaulted write ‘me too’ as a reply to this tweet. <a href="https://t.co/k2oeCiUf9n">pic.twitter.com/k2oeCiUf9n</a></p>&mdash; Alyssa Milano (@Alyssa_Milano) <a href="https://twitter.com/Alyssa_Milano/status/919659438700670976?ref_src=twsrc%5Etfw">15 October 2017</a></blockquote>

      <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">One tweet has brought together 1.7 million voices from 85 countries. Standing side by side, together, our movement will only grow.  <a href="https://twitter.com/hashtag/MeToo?src=hash&amp;ref_src=twsrc%5Etfw">#MeToo</a></p>&mdash; Alyssa Milano (@Alyssa_Milano) <a href="https://twitter.com/Alyssa_Milano/status/922826342890131456?ref_src=twsrc%5Etfw">24 October 2017</a></blockquote>

      <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">President Obama Considers Revoking Bil Cosby&#39;s Medal of Freedom <a href="https://t.co/smDkCP19PK">https://t.co/smDkCP19PK</a> <a href="https://t.co/Ra3jdUNHgg">pic.twitter.com/Ra3jdUNHgg</a></p>&mdash; BET (@BET) <a href="https://twitter.com/BET/status/685945925395681281?ref_src=twsrc%5Etfw">9 January 2016</a></blockquote>

      <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">Harvey Weinstein turns himself in to the NYPD <a href="https://t.co/VKd9IhrWdj">https://t.co/VKd9IhrWdj</a> <a href="https://t.co/u8SELwAIdw">pic.twitter.com/u8SELwAIdw</a></p>&mdash; Variety (@Variety) <a href="https://twitter.com/Variety/status/999983463074881537?ref_src=twsrc%5Etfw">25 May 2018</a></blockquote>

      <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">Nothing is more heartbreaking and eye opening than asking students if they were ever sexually harassed, and ALL their hands go up. <a href="https://twitter.com/hashtag/metoo?src=hash&amp;ref_src=twsrc%5Etfw">#metoo</a></p>&mdash; Nicholas Ferroni (@NicholasFerroni) <a href="https://twitter.com/NicholasFerroni/status/922835219815845889?ref_src=twsrc%5Etfw">24 October 2017</a></blockquote>

      <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">Why didn’t you say something? <br><br>I did.<br><br>Why didn’t you push him off?<br><br>I did.<br><br>Why didn’t you cuss him out?<br><br>I did.<br><br>Why didn’t you tell the police?<br><br>I did.<br><br>Why didn’t you press charges?<br><br>I did.<br><br>Why did you just let it happen?<br><br>I didn’t.<br><br>Why didn’t you beat him up?<br><br>(Sigh)</p>&mdash; terry crews (@terrycrews) <a href="https://twitter.com/terrycrews/status/1012677642439385089?ref_src=twsrc%5Etfw">29 June 2018</a></blockquote>

      <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">As a male childhood rape survivor who has gone public and had so many disclose to me, I promise: any rape stat you see is low.  <a href="https://twitter.com/hashtag/MeToo?src=hash&amp;ref_src=twsrc%5Etfw">#MeToo</a></p>&mdash; Barry Crimmins (@crimmins) <a href="https://twitter.com/crimmins/status/919692949067268096?ref_src=twsrc%5Etfw">15 October 2017</a></blockquote>

      <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">Christine Blasey Ford presented SI&#39;s Inspiration of the Year Award to Rachael Denhollander, 1st woman to publicly accuse former USA Gymnastics doctor Larry Nassar of sexual assault, in 1 of Ford&#39;s 1st public statements since her Kavanaugh hearing testimony <a href="https://t.co/0v7Tt7aY6d">https://t.co/0v7Tt7aY6d</a> <a href="https://t.co/ROonSOhSAM">pic.twitter.com/ROonSOhSAM</a></p>&mdash; CNN (@CNN) <a href="https://twitter.com/CNN/status/1073007057727565825?ref_src=twsrc%5Etfw">13 December 2018</a></blockquote>

      <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">Because I was shamed and considered a &quot;party girl&quot; I felt I deserved it. I shouldnt have been there, I shouldn&#39;t have been &quot;bad&quot; <a href="https://twitter.com/hashtag/metoo?src=hash&amp;ref_src=twsrc%5Etfw">#metoo</a></p>&mdash; #EvanRachelWould (@evanrachelwood) <a href="https://twitter.com/evanrachelwood/status/919778625536245760?ref_src=twsrc%5Etfw">16 October 2017</a></blockquote>

      <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="und" dir="ltr"><a href="https://twitter.com/hashtag/MeToo?src=hash&amp;ref_src=twsrc%5Etfw">#MeToo</a></p>&mdash; Lady Gaga (@ladygaga) <a href="https://twitter.com/ladygaga/status/919698717392887808?ref_src=twsrc%5Etfw">15 October 2017</a></blockquote>

    `,
    wikipediaURL: 'https://en.wikipedia.org/wiki/Me_Too_movement',
    imageURL: require('../static/images/me2.jpeg'),
    body: `
    <p>
      The Me Too movement (or #MeToo movement) is a movement against sexual harassment and sexual assault that was founded by Tarana Burke in 2006. The movement began to spread virally in October 2017 as a hashtag on social media in an attempt to demonstrate the widespread prevalence of sexual assault and harassment, especially in the workplace. It followed sexual-abuse allegations against Harvey Weinstein. Tarana Burke’s phrase was popularized by American actress Alyssa Milano on Twitter in 2017. Milano encouraged victims of sexual harassment to tweet about it and "give people a sense of the magnitude of the problem
    </p>
    `,
  },

  // Article 2.
  article_2: {
    key:'2',
    href:'russia-investigation',
    header: 'Russia Investigation',
    subheader: 'Etc ...',
    twitter: `
    <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">NYT Breaking: Probe into Trump Russia investigation becomes criminal investigation <a href="https://t.co/nL8BAzvgg8">pic.twitter.com/nL8BAzvgg8</a></p>&mdash; Maddow Blog (@MaddowBlog) <a href="https://twitter.com/MaddowBlog/status/1187537623839117312?ref_src=twsrc%5Etfw">25 October 2019</a></blockquote>

    <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">Trump&#39;s lawyers argued president could not have obstructed Russia probe: NYT <a href="https://t.co/IidnThDSZy">https://t.co/IidnThDSZy</a> <a href="https://t.co/MZKOfnhVPB">pic.twitter.com/MZKOfnhVPB</a></p>&mdash; Reuters (@Reuters) <a href="https://twitter.com/Reuters/status/1003046871626145792?ref_src=twsrc%5Etfw">2 June 2018</a></blockquote>

    <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">President Trump&#39;s tweet about the Russia investigation sparked a blistering nine-tweet attack from Kellyanne Conway&#39;s husband, George, in which he called Trump a &quot;malignant narcissist.&quot; CNN&#39;s Brian Todd reports. <a href="https://t.co/Q873R7XMMH">https://t.co/Q873R7XMMH</a> <a href="https://t.co/5VUoKmNyGE">pic.twitter.com/5VUoKmNyGE</a></p>&mdash; CNN (@CNN) <a href="https://twitter.com/CNN/status/1128076230677475329?ref_src=twsrc%5Etfw">13 May 2019</a></blockquote>

    <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">President Trump reverses his remark on Russia meddling in the US election <a href="https://t.co/OBwVGTjUxZ">https://t.co/OBwVGTjUxZ</a> <a href="https://t.co/ooAP1QHTFL">pic.twitter.com/ooAP1QHTFL</a></p>&mdash; BBC News (World) (@BBCWorld) <a href="https://twitter.com/BBCWorld/status/1019315605982326801?ref_src=twsrc%5Etfw">17 July 2018</a></blockquote>

    `,
    wikipediaURL: 'https://en.wikipedia.org/wiki/Special_Counsel_investigation_(2017%E2%80%932019)',
    imageURL: require('../static/images/russia.jpg'),
    body: `
    <p>
      The Special Counsel investigation was an investigation of Russian interference in the 2016 US elections and suspicious links between Trump associates and Russian officials, conducted by special prosecutor Robert Mueller from May 2017 to March 2019. It was also called the Russia investigation, the Mueller probe, and the Mueller investigation. Since July 2016, the Federal Bureau of Investigation (FBI) had been covertly investigating activities by Russian operatives and by members of the Trump presidential campaign, under the code name "Crossfire Hurricane". In May 2017, President Donald Trump dismissed FBI Director James Comey, because he was critical of Comey's handling of the Clinton and Russia probes. Within 8 days, following a call to action by Democratic lawmakers and revelations by Comey, Deputy Attorney General Rod Rosenstein appointed Mueller, a former FBI director, to take over the FBI's work. According to its authorizing document, the investigation's scope included allegations of "links and/or coordination" between the Russian government and individuals associated with the Trump campaign. Mueller was also mandated to pursue "any matters that arose or may arise directly from the investigation." The probe included a criminal investigation which looked into potential obstruction of justice charges against Trump and members of his campaign or his administration.
    </p>
    `,
  },

  // Article 3.
  article_3: {
    key:'3',
    header: 'Meghan & Harry Wedding',
    href:'meghan-&-harry-wedding',
    subheader: 'Etc ...',
    twitter: `
    <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">Royal wedding front pages around the world: in pictures <a href="https://t.co/NLfwMnR4NA">https://t.co/NLfwMnR4NA</a> <a href="https://t.co/ccFacIV4Lu">pic.twitter.com/ccFacIV4Lu</a></p>&mdash; The Guardian (@guardian) <a href="https://twitter.com/guardian/status/998176725845663744?ref_src=twsrc%5Etfw">20 May 2018</a></blockquote>

    <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">Looks like a lovely day in UK for Harry and Meghan’s wedding <a href="https://twitter.com/hashtag/royalwedding?src=hash&amp;ref_src=twsrc%5Etfw">#royalwedding</a></p>&mdash; Billy Idol (@BillyIdol) <a href="https://twitter.com/BillyIdol/status/997779491790376961?ref_src=twsrc%5Etfw">19 May 2018</a></blockquote>

    <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">Royal wedding 2018: US Bishop wows with address <a href="https://t.co/5tI0ZfgK9R">https://t.co/5tI0ZfgK9R</a></p>&mdash; BBC News (World) (@BBCWorld) <a href="https://twitter.com/BBCWorld/status/997815829956788230?ref_src=twsrc%5Etfw">19 May 2018</a></blockquote>

    `,
    wikipediaURL: 'https://en.wikipedia.org/wiki/Wedding_of_Prince_Harry_and_Meghan_Markle',
    imageURL: require('../static/images/wedding.jpg'),
    body: `
    <p>
      The wedding of Prince Harry and Meghan Markle was held on 19 May 2018 in St George's Chapel at Windsor Castle in the United Kingdom. The groom, Prince Harry, is a member of the British royal family; the bride, Meghan Markle, is American and previously worked as an actress. The traditional ceremony was noted for the inclusion of African American culture.
    </p>
    `,
  },

  // Article 4.
  article_4: {
    key:'4',
    href:'brexit',
    header: 'Brexit',
    subheader: 'Etc ...',
    twitter: `
      <blockquote class="twitter-tweet" data-lang="en-gb" data-width="100%"><p lang="en" dir="ltr">This audience member wants politicians to get on with Brexit and to cut out the rhetoric <a href="https://twitter.com/hashtag/bbcqt?src=hash&amp;ref_src=twsrc%5Etfw">#bbcqt</a> <a href="https://t.co/zX43Azei7a">pic.twitter.com/zX43Azei7a</a></p>&mdash; BBC Question Time (@bbcquestiontime) <a href="https://twitter.com/bbcquestiontime/status/860257940548141056?ref_src=twsrc%5Etfw">4 May 2017</a></blockquote>

      <blockquote class="twitter-tweet" data-lang="en-gb" data-width="100%"><p lang="en" dir="ltr">The election on June 8th is our last chance to stop the Tories&#39; extreme &#39;hard Brexit&#39; <a href="https://twitter.com/hashtag/GE2017?src=hash&amp;ref_src=twsrc%5Etfw">#GE2017</a> <a href="https://twitter.com/hashtag/BBCQT?src=hash&amp;ref_src=twsrc%5Etfw">#BBCQT</a> <a href="https://t.co/B3yi9nNVkA">https://t.co/B3yi9nNVkA</a></p>&mdash; Sadiq Khan (@SadiqKhan) <a href="https://twitter.com/SadiqKhan/status/860256993382195201?ref_src=twsrc%5Etfw">4 May 2017</a></blockquote>

      <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">George Soros: ‘Brexit hurts both sides – my money was used to educate the British public’ <a href="https://t.co/41xQXURu7y">https://t.co/41xQXURu7y</a></p>&mdash; The Guardian (@guardian) <a href="https://twitter.com/guardian/status/1190548051221463040?ref_src=twsrc%5Etfw">2 November 2019</a></blockquote>

      <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">‘How do I serve the cause of Brexit best?’ The answer to that, according to Brexit Party’s Nigel Farage, is not to stand for election, but travel across Britain supporting candidates <a href="https://t.co/EJqwnLZ9iG">https://t.co/EJqwnLZ9iG</a> <a href="https://t.co/6V5KkdWDHx">pic.twitter.com/6V5KkdWDHx</a></p>&mdash; Reuters (@Reuters) <a href="https://twitter.com/Reuters/status/1191057453003296770?ref_src=twsrc%5Etfw">3 November 2019</a></blockquote>

      `,
    wikipediaURL: 'https://en.wikipedia.org/wiki/Brexit',
    imageURL: require('../static/images/brexit.jpg'),
    body: `
    <p>
    Brexit (a portmanteau of "British" and "exit") is the scheduled withdrawal of the United Kingdom from the European Union. Following a June 2016 referendum, in which 51.9% of participating voters voted to leave, the UK government formally announced the country's withdrawal in March 2017, starting a two-year process that was due to conclude with the UK withdrawing on 29 March 2019. As the UK parliament thrice voted against the negotiated withdrawal agreement, that deadline has been extended twice, and is currently 31 October 2019. The 'Benn Act' that passed in Parliament requires the government to seek a third extension if no agreement is reached before 19 October.
    </p>
    `,
  },
  // Article 5.
  article_5: {
    key:'5',
    href:'us-election',
    header: 'US Election',
    subheader: 'Etc ...',
    twitter: `
    <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">Rubio on Trump: &quot;He has no chance in a general election. A vote for Donald Trump is a vote for Hillary Clinton.&quot; <a href="https://t.co/wcD1W2F6mT">https://t.co/wcD1W2F6mT</a></p>&mdash; ABC News (@ABC) <a href="https://twitter.com/ABC/status/704874536542429185?ref_src=twsrc%5Etfw">2 March 2016</a></blockquote>

    <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">US election: Donald Trump says Hillary Clinton is &#39;the devil&#39; <a href="https://t.co/dftVta463J">https://t.co/dftVta463J</a></p>&mdash; BBC News (World) (@BBCWorld) <a href="https://twitter.com/BBCWorld/status/760306165465178112?ref_src=twsrc%5Etfw">2 August 2016</a></blockquote>

    <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">With the election just days away, Donald Trump and Hillary Clinton begin their weekend sprint <a href="https://t.co/zoKBgzYLNC">https://t.co/zoKBgzYLNC</a> <a href="https://t.co/EAAvI6TUMB">pic.twitter.com/EAAvI6TUMB</a></p>&mdash; CNN (@CNN) <a href="https://twitter.com/CNN/status/795039269802872832?ref_src=twsrc%5Etfw">5 November 2016</a></blockquote>

    <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">Hillary Clinton has a massive cash advantage over Donald Trump with only 11 days to go until Election Day. <a href="https://t.co/6PCqhQL5pw">https://t.co/6PCqhQL5pw</a> <a href="https://t.co/dETZiAJvO6">pic.twitter.com/dETZiAJvO6</a></p>&mdash; ABC News (@ABC) <a href="https://twitter.com/ABC/status/792175438118416384?ref_src=twsrc%5Etfw">29 October 2016</a></blockquote>

    <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">Donald Trump, Hillary Clinton, Mike Pence and Tim Kaine all cast their ballots for the 2016 election today. <a href="https://t.co/U3e0DU89F7">https://t.co/U3e0DU89F7</a> <a href="https://t.co/XmohQzUhmr">pic.twitter.com/XmohQzUhmr</a></p>&mdash; ABC News (@ABC) <a href="https://twitter.com/ABC/status/796059544677978112?ref_src=twsrc%5Etfw">8 November 2016</a></blockquote>

    <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">Watch Hillary Clinton&#39;s first public appearance since conceding the election to Donald Trump <a href="https://t.co/IXaKvhlAiC">pic.twitter.com/IXaKvhlAiC</a></p>&mdash; The Guardian (@guardian) <a href="https://twitter.com/guardian/status/799180256611471362?ref_src=twsrc%5Etfw">17 November 2016</a></blockquote>

    `,
    wikipediaURL: 'https://en.wikipedia.org/wiki/2016_United_States_presidential_election',
    imageURL: require('../static/images/us_election.jpg'),
    body: `
    <p>
      The 2016 United States presidential election was held on Tuesday, November 8, 2016. The Republican ticket of businessman Donald Trump and Indiana Governor Mike Pence defeated the Democratic ticket of former Secretary of State Hillary Clinton and U.S. Senator from Virginia Tim Kaine, despite losing the popular vote. Trump took office as the 45th president, and Pence as the 48th vice president, on January 20, 2017.
    </p>
    `,
  },

  article_6: {
    key:'6',
    href:'climate-change',
    header: 'Climate Change',
    subheader: 'Etc ...',
    twitter: `
    <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">Former Exxon Mobil CEO Rex Tillerson testifies in a civil lawsuit accusing the company of causing investors a loss of up to $1.6 billion by hiding the true cost of climate change <a href="https://t.co/QNlTiiomTy">https://t.co/QNlTiiomTy</a> <a href="https://t.co/WadZdWYwdb">pic.twitter.com/WadZdWYwdb</a></p>&mdash; Reuters (@Reuters) <a href="https://twitter.com/Reuters/status/1189699756756389888?ref_src=twsrc%5Etfw">31 October 2019</a></blockquote>

    <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">Here are 5 reasons deadlier &amp; more destructive wildfires have become a new normal ⁠— all related to climate change:<br><br>-It&#39;s getting hotter &amp; hotter<br>-Earlier snowmelts<br>-Drier conditions<br>-Fire season becomes &#39;fire year&#39;<br>-Pests weaken trees<br>-Burn areas grow<a href="https://t.co/U0YL9nJQ3R">https://t.co/U0YL9nJQ3R</a></p>&mdash; CNN (@CNN) <a href="https://twitter.com/CNN/status/1189337784726806530?ref_src=twsrc%5Etfw">30 October 2019</a></blockquote>

    <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">Actress Jane Fonda to the spend night in jail after the latest climate change protest. <a href="https://t.co/qU0lAAIQAl">https://t.co/qU0lAAIQAl</a></p>&mdash; ABC News (@ABC) <a href="https://twitter.com/ABC/status/1190432593986105344?ref_src=twsrc%5Etfw">2 November 2019</a></blockquote>

    <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">Spain offers to host COP25 climate change summit after Chile&#39;s withdrawal <a href="https://t.co/yasZx2vlZQ">https://t.co/yasZx2vlZQ</a> <a href="https://t.co/T6YGEuXeJl">pic.twitter.com/T6YGEuXeJl</a></p>&mdash; Reuters (@Reuters) <a href="https://twitter.com/Reuters/status/1189962286703747072?ref_src=twsrc%5Etfw">31 October 2019</a></blockquote>

    <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">Study suggests 300 million people are now living on land that is likely to flood at least once a year by 2050 due to climate change, with China, Bangladesh, India and Vietnam accounting for the bulk of at-risk population <a href="https://t.co/xHIPyc3pAK">https://t.co/xHIPyc3pAK</a> <a href="https://t.co/4gu01OJyfu">pic.twitter.com/4gu01OJyfu</a></p>&mdash; Reuters (@Reuters) <a href="https://twitter.com/Reuters/status/1189406742129627136?ref_src=twsrc%5Etfw">30 October 2019</a></blockquote>

    <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">&#39;The climate doesn&#39;t need awards&#39;: Greta Thunberg declines environmental prize <a href="https://t.co/7KfZCkAolK">https://t.co/7KfZCkAolK</a></p>&mdash; The Guardian (@guardian) <a href="https://twitter.com/guardian/status/1189309915891941378?ref_src=twsrc%5Etfw">29 October 2019</a></blockquote>

    `,
    wikipediaURL: 'https://en.wikipedia.org/wiki/Climate_change',
    imageURL: require('../static/images/climate_chang.jpeg'),
    body: `
    <p>
      Climate change occurs when changes in Earth's climate system result in new weather patterns that remain in place for an extended period of time. This length of time can be as short as a few decades to as long as millions of years. The climate system receives nearly all of its energy from the sun, with a relatively tiny amount from earth's interior. The climate system also gives off energy to outer space. The balance of incoming and outgoing energy, and the passage of the energy through the climate system, determines Earth's energy budget. When the incoming energy is greater than the outgoing energy, earth's energy budget is positive and the climate system is warming. If more energy goes out, the energy budget is negative and earth experiences cooling.
    </p>
    `,
  },
  // Article 7.
  article_7: {
    key:'7',
    href:'us-immigration-ban',
    header: 'US Immigration Ban',
    subheader: 'Etc ...',
    twitter: `
    <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">.<a href="https://twitter.com/realDonaldTrump?ref_src=twsrc%5Etfw">@realDonaldTrump</a> cites a conspiracy theorist in his immigration ban proposal <a href="https://t.co/xzPky1utHT">https://t.co/xzPky1utHT</a> <a href="https://t.co/ycTAYULWC7">pic.twitter.com/ycTAYULWC7</a></p>&mdash; CNN (@CNN) <a href="https://twitter.com/CNN/status/674035279540408322?ref_src=twsrc%5Etfw">8 December 2015</a></blockquote>

    <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">DHS Sec. Kelly says immigration order is &quot;not a ban on Muslims. The Homeland Security mission is to safeguard the American people.&quot; <a href="https://t.co/YFKk14mrux">pic.twitter.com/YFKk14mrux</a></p>&mdash; ABC News (@ABC) <a href="https://twitter.com/ABC/status/826481477726507008?ref_src=twsrc%5Etfw">31 January 2017</a></blockquote>

    <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">US immigration order: Families split as Trump ban enforced <a href="https://t.co/hOFHR7j6tX">https://t.co/hOFHR7j6tX</a></p>&mdash; BBC News (World) (@BBCWorld) <a href="https://twitter.com/BBCWorld/status/825663309017923585?ref_src=twsrc%5Etfw">29 January 2017</a></blockquote>

    <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">Legal battles to test Trump and his immigration ban. Via <a href="https://twitter.com/ReutersTV?ref_src=twsrc%5Etfw">@ReutersTV</a> <a href="https://t.co/djGgVXm9WU">https://t.co/djGgVXm9WU</a> <a href="https://t.co/5BnR0eiOns">pic.twitter.com/5BnR0eiOns</a></p>&mdash; Reuters (@Reuters) <a href="https://twitter.com/Reuters/status/828608681461825537?ref_src=twsrc%5Etfw">6 February 2017</a></blockquote>

    <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">Senate Democrats push for vote on bill to rescind President Trump&#39;s immigration ban <a href="https://t.co/7zYsk7Y98g">https://t.co/7zYsk7Y98g</a> <a href="https://t.co/d5c60QAVbQ">pic.twitter.com/d5c60QAVbQ</a></p>&mdash; CNN (@CNN) <a href="https://twitter.com/CNN/status/826086574144311296?ref_src=twsrc%5Etfw">30 January 2017</a></blockquote>

    <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">Judge blocks Trump immigration ban <a href="https://t.co/oSM3rrLgzW">https://t.co/oSM3rrLgzW</a></p>&mdash; BBC News (World) (@BBCWorld) <a href="https://twitter.com/BBCWorld/status/827674240778399744?ref_src=twsrc%5Etfw">4 February 2017</a></blockquote>

    `,
    wikipediaURL: 'https://en.wikipedia.org/wiki/Executive_Order_13769',
    imageURL: require('../static/images/us_immigration_ban.jpg'),
    body: `
    <p>
      Executive Order 13769, titled Protecting the Nation from Foreign Terrorist Entry into the United States, also known as the Muslim ban or travel ban, was an executive order by US president Trump. Except for the extent to which it was blocked by various courts, it was in effect from January 27, 2017, until March 16, 2017, when it was superseded by Executive Order 13780. Executive Order 13769 lowered the number of refugees to be admitted into the US in 2017 to 50,000, suspended the U.S. Refugee Admissions Program for 120 days, suspended the entry of Syrian refugees indefinitely, directed some cabinet secretaries to suspend entry of those whose countries do not meet adjudication standards under U.S. immigration law for 90 days, and included exceptions on a case-by-case basis. Homeland Security lists these countries as Iran, Iraq, Libya, Somalia, Sudan, Syria, and Yemen. More than 700 travelers were detained, and up to 60,000 visas were "provisionally revoked".
    </p>
    `,
  },

  // Article 8.
  article_8: {
    key:'8',
    href: 'water-discovered-on-mars',
    header: 'Water Discovered On Mars',
    subheader: 'Etc ...',
    twitter: `
    <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">After years of research, scientists have confirmed that liquid water exists on Mars. This could allow humans to further explore the red planet. <a href="https://t.co/22Cygnq7Lh">https://t.co/22Cygnq7Lh</a> <a href="https://t.co/8EHaYL9Zp8">pic.twitter.com/8EHaYL9Zp8</a></p>&mdash; CNN (@CNN) <a href="https://twitter.com/CNN/status/1022136796342304768?ref_src=twsrc%5Etfw">25 July 2018</a></blockquote>

    <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">Nasa scientists find evidence of flowing water on Mars <a href="http://t.co/6ZIPM9rEQ6">http://t.co/6ZIPM9rEQ6</a></p>&mdash; The Guardian (@guardian) <a href="https://twitter.com/guardian/status/648513106851823616?ref_src=twsrc%5Etfw">28 September 2015</a></blockquote>

    <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">Highligts from NASA&#39;s announcement on the discovery of water flows on Mars. <a href="http://t.co/IY9h4SwY9q">http://t.co/IY9h4SwY9q</a> <a href="http://t.co/3wVolKChtR">pic.twitter.com/3wVolKChtR</a></p>&mdash; Reuters (@Reuters) <a href="https://twitter.com/Reuters/status/648564860687642628?ref_src=twsrc%5Etfw">28 September 2015</a></blockquote>

    <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">The European Space Agency released an image taken by its Mars Express mission showing the Korolev crater filled with water ice. The crater is more than 50 miles across and located just south of the Red Planet&#39;s northern polar cap. <a href="https://t.co/HOT1uVhNKG">https://t.co/HOT1uVhNKG</a> <a href="https://t.co/SSJv5HC0jy">pic.twitter.com/SSJv5HC0jy</a></p>&mdash; CNN (@CNN) <a href="https://twitter.com/CNN/status/1075846067298668544?ref_src=twsrc%5Etfw">20 December 2018</a></blockquote>

    <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">A visual guide to water on Mars <a href="http://t.co/bJ25LOQMdw">http://t.co/bJ25LOQMdw</a> <a href="http://t.co/MHmxSEPSzj">pic.twitter.com/MHmxSEPSzj</a></p>&mdash; The Guardian (@guardian) <a href="https://twitter.com/guardian/status/648519525059665920?ref_src=twsrc%5Etfw">28 September 2015</a></blockquote>

`,
    wikipediaURL: 'https://en.wikipedia.org/wiki/Water_on_Mars',
    imageURL: require('../static/images/water_descovered_on_mars.jpg'),
    body: `
    <p>
      On November 22, 2016, NASA reported finding a large amount of underground ice on Mars; the volume of water detected is equivalent to the volume of water in Lake Superior. In July 2018, Italian scientists reported the discovery of a subglacial lake on Mars, 1.5 km (0.93 mi) below the southern polar ice cap, and extending sideways about 20 km (12 mi), the first known stable body of water on the planet.
    </p>
    `,
  },

  // article_9: {
  //   key:'9',
  //   href: 'xxx',
  //   header: 'XXX',
  //   subheader: 'Etc ...',
  //   twitter: ``,
  //   wikipediaURL: '',
  //   imageURL: require('../static/images/break.png'),
  //   body: `
  //   <p>
  //   </p>
  //   `,
  // },

// Article 10.
article_10: {
  key:'10',
  href: 'mh370-disappearance',
  header: 'MH370 Disappearance',
  subheader: 'Etc ...',
  twitter: `
  <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">Malaysian authorities reveal final communication of missing flight <a href="https://twitter.com/hashtag/MH370?src=hash&amp;ref_src=twsrc%5Etfw">#MH370</a> <a href="http://t.co/KCdffxTgVk">http://t.co/KCdffxTgVk</a> <a href="https://twitter.com/hashtag/Instafax?src=hash&amp;ref_src=twsrc%5Etfw">#Instafax</a></p>&mdash; BBC News (World) (@BBCWorld) <a href="https://twitter.com/BBCWorld/status/443732891135377409?ref_src=twsrc%5Etfw">12 March 2014</a></blockquote>

  <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr"><a href="https://twitter.com/hashtag/MH370?src=hash&amp;ref_src=twsrc%5Etfw">#MH370</a>: Search begins in southern Indian Ocean <a href="http://t.co/syuEWxQzWL">http://t.co/syuEWxQzWL</a> <a href="http://t.co/9vqIrVBQcN">pic.twitter.com/9vqIrVBQcN</a></p>&mdash; BBC News (World) (@BBCWorld) <a href="https://twitter.com/BBCWorld/status/518980937338880000?ref_src=twsrc%5Etfw">6 October 2014</a></blockquote>

  <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">We&#39;ve been looking in the wrong place, MH370 search team says <a href="https://t.co/4GnuWnVviq">https://t.co/4GnuWnVviq</a> <a href="https://t.co/d4MnCcGCOv">pic.twitter.com/d4MnCcGCOv</a></p>&mdash; Reuters (@Reuters) <a href="https://twitter.com/Reuters/status/755976864087306245?ref_src=twsrc%5Etfw">21 July 2016</a></blockquote>

  <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr"><a href="https://twitter.com/hashtag/MH370?src=hash&amp;ref_src=twsrc%5Etfw">#MH370</a> searchers have discovered extinct volcanoes, but still no sign of the missing airliner. <a href="http://t.co/ChXT35PWlS">http://t.co/ChXT35PWlS</a> <a href="http://t.co/ayQ3j7NDI6">pic.twitter.com/ayQ3j7NDI6</a></p>&mdash; CNN (@CNN) <a href="https://twitter.com/CNN/status/519520887855665154?ref_src=twsrc%5Etfw">7 October 2014</a></blockquote>

  <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr"><a href="https://twitter.com/hashtag/MH370?src=hash&amp;ref_src=twsrc%5Etfw">#MH370</a> search: New debris found on Madagascar beach<a href="https://t.co/gwiKYWNWXP">https://t.co/gwiKYWNWXP</a> <a href="https://t.co/sd9Q4CRuvY">pic.twitter.com/sd9Q4CRuvY</a></p>&mdash; BBC News (World) (@BBCWorld) <a href="https://twitter.com/BBCWorld/status/741077565947924481?ref_src=twsrc%5Etfw">10 June 2016</a></blockquote>

  <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">The underwater search for <a href="https://twitter.com/hashtag/MH370?src=hash&amp;ref_src=twsrc%5Etfw">#MH370</a> has been suspended, almost 3 years after it vanished with 239 people on board <a href="https://t.co/HLn3z7AsrP">https://t.co/HLn3z7AsrP</a> <a href="https://t.co/XTHvWQOy8B">pic.twitter.com/XTHvWQOy8B</a></p>&mdash; CNN (@CNN) <a href="https://twitter.com/CNN/status/821241259318792192?ref_src=twsrc%5Etfw">17 January 2017</a></blockquote>

  <blockquote class="twitter-tweet" data-lang="en-gb"><p lang="en" dir="ltr">JUST IN: Investigators say unable to determine real cause for disappearance of Malaysia Airlines flight <a href="https://twitter.com/hashtag/MH370?src=hash&amp;ref_src=twsrc%5Etfw">#MH370</a> <a href="https://t.co/YlVL6GTTb7">pic.twitter.com/YlVL6GTTb7</a></p>&mdash; Reuters (@Reuters) <a href="https://twitter.com/Reuters/status/1023830617853775872?ref_src=twsrc%5Etfw">30 July 2018</a></blockquote>

  `,
  wikipediaURL: 'https://en.wikipedia.org/wiki/Malaysia_Airlines_Flight_370',
  imageURL: require('../static/images/mh370.jpeg'),
  body: `
  <p>
    Malaysia Airlines Flight 370 (also referred to as MH370 or MAS370) was a scheduled international passenger flight operated by Malaysia Airlines that disappeared on 8 March 2014 while flying from Kuala Lumpur International Airport to its destination, Beijing Capital International Airport. The crew of the Boeing 777-200ER aircraft last communicated with air traffic control (ATC) around 38 minutes after takeoff when the flight was over the South China Sea. With all 227 passengers and 12 crew aboard presumed dead, the disappearance of Flight 370 was the deadliest incident involving a Boeing 777 and the deadliest in Malaysia Airlines' history until it was surpassed in both regards by Malaysia Airlines Flight 17, which was shot down while flying over eastern Ukraine four months later.
  </p>
  `,
},

  // end

}
/*
// Article 1.
article_1: {
  key:'1',
  header: 'Hash Takes A Break',
  subheader: 'Etc ...',
  twitterURLs: [],
  wikipediaURL: '',
  imageURL: require('../static/images/break.png'),
  body: `
  <p>
  </p>
  `,
},
*/


// Page Overview
export const overview: Article = {
  key: 'p',
  href: 'hash-takes-a-break',
  header: 'Hash Takes A Break',
  subheader: 'Etc ...',
  twitter: ``,
  wikipediaURL: '',
  imageURL: require('../static/images/break.png'),
  body: `
  <p><b>
    From today, Hash will no longer surface the stories people are talking about. The site will remain online as a snapshot, while we rethink the future of news and explore new opportunities.
  </b></p>
  <p>
    Back in 2014, two driving factors led to us creating Hash – firstly, Twitter’s lack of a logged-out experience left a vast amount of awesome content inaccessible to a large audience. Secondly, the online news industry had long been an overwhelming and frustrating place – we wanted to create something that would let people catch up on important events quickly. Hash was our answer: aggregating tweets about the day’s most topical stories in a simple, visual way.
  </p>
  <p>
    Since then we’ve learnt a hell of a lot about news and algorithms. That’s why we’ve decided to pause Hash, rethink how we approach news aggregation and try something else. If you’d like to stay up-to-date with what we’re up to, you can sign up here.
    <br>
    <a class="button" href="#">Stay in the know</a>
  </p>
  <p>
    In the few years since Hash was a twinkle in our eye, we’ve been humbled not only by the organic growth of our user base, but by the consistent influx of enthusiasm, job applications and support, which have brought a new layer of meaning to the work that we do. Hash has always been free, but as users, you have invested your time, effort and support in us – for that, we thank you.
  </p>
  <p>
    Here’s to the future of agenda-free news, whatever form it may come in.
  </p>
  <p class="the-team">The Hash team</p>
  `
}
