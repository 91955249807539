// Colors.
export const colors = {
  black: '#333333',
  red: '#EB5757'
}

// Scroll To Element With ID.
export const scrollToElementWithID = (id: string) => {
  // console.log('SCROLL TO ELEMENT WITH ID', id)
  const element = document.getElementById(id)
  if (element && window.screen.width > 500) {
    const offsetLeft = element.offsetLeft
    if (offsetLeft || offsetLeft === 0) document.documentElement.scrollTo({left: offsetLeft, behavior: 'smooth'})
    // console.log('found element', element, offsetLeft)
  }
}
