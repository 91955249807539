// Dependencies.
import React from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom'

// Pages.
import {IndexPage} from './pages'

// Router.
export const Router: React.FC = props => <BrowserRouter>

  <Switch>
    <Route path="/story/:id" component={IndexPage}/>
    <Route path="/" component={IndexPage}/>
  </Switch>

</BrowserRouter>
