// Dependencies.
import React from 'react'
import {articles, overview} from '../utils/articles'
import {RouteComponentProps} from 'react-router'
import styled from 'styled-components'

// Components.
import {Article} from '../components/Article'
// import {H2} from '../components/H2'
import { px, H1BoxTitle, H3BoxTitle, H2Year } from '../components/Text'

// Props.
interface Props extends RouteComponentProps<{id: string}> {}

// Index Page.
export const IndexPage: React.FC<Props> = props => {
  // Props.
  const {pathname} = props.location
  const {id} = props.match.params

  // ..
  return <Page>

    {/* hero */}
    <BlueBox>
      <HashImage src={require('../static/images/hash-tag.svg')}/>
      <BoxTitle>
        Hash brings you the stories people are talking about.
      </BoxTitle>
      <Year>2014-2019</Year>
    </BlueBox>

    {/* Page Overview */}
    <Article {...overview} currentArticleID={id} shouldBeOpen={pathname === `/story/${overview.href}`}/>

    {/* next pointer */}
    <BlueBoxTopTen>
      <TopTenTitle>
        <span role="img" aria-label="go to next">👉</span><br/>
        Top ten stories to trend on Hash
      </TopTenTitle>
    </BlueBoxTopTen>

    {/* Articles. */}
    {Object.values(articles).map(article => <Article {...article} currentArticleID={id} shouldBeOpen={pathname === `/story/${article.href}`}/>)}

  </Page>

}

const Page = styled.div`
  display: flex;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`

// the text break with blue background
const BlueBox = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  width: 480px;
  height: 100vh;
  background: linear-gradient(151.93deg, #6C8ABA 0%, #B5E4DE 100%);
  color: white;

  @media (max-width: 500px) {
    align-items: flex-start;
    width: calc(100% - 60px);
    height: auto;
    padding: 80px 30px;

  }
`

const BlueBoxTopTen = styled(BlueBox)`
  align-items: center;
  @media (max-width: 500px) {
    align-items: center;
  }
`

// # image inside the first BlueBox
const HashImage = styled.img`
  width: 73px;
  height: auto;
  margin-left: 50px;
  margin-bottom: 20px;
  @media (max-width: 500px) {
    margin-left: 0px;
    width: 48px;
  }
`

// title inside the first BlueBox
const BoxTitle = styled(H1BoxTitle)`
  width: 380px;
  margin-bottom: 20px;
  margin-left: 50px;
  @media (max-width: 500px) {
    margin-left: 0px;
    width: 270px;
  }
`
// the year inside the first BlueBox
const Year = styled(H2Year)`
  margin-left: 50px;
  @media (max-width: 500px) {
    margin-left: unset;
  }
`

// the title inside the second BlueBox
const TopTenTitle = styled(H3BoxTitle)`
  width: 234px;
  /* margin-left: 123px; */

  @media (max-width: 500px) {
    width: 100%;
    /* margin-left: unset; */
    max-width: 200px;
  }

  span {
    font-size: ${px(72)};
    line-height: ${px(84)};
    @media (max-width: 500px) {
      font-size: ${px(30)};
      line-height: ${px(30)};
      display: inline-block;
      margin-bottom: 10px;
    }
  }

`
