// imports
import styled from 'styled-components'
import WikiImageUrl from '../static/images/icon-wiki.png'
import WikiImageInvertedUrl from '../static/images/icon-wiki-inv.png'

// text helpers
export const px = function (pixels: number) {
  return `${(pixels/16)}rem`
}

export const CssBlackText = 'rgb(39, 39, 39)';

// text
export const H1BoxTitle = styled.h1`
  font-family: 'BentonSans', sans-serif;
  font-weight: normal;
  font-size: ${px(36)};
  line-height: ${px(42)};
  margin: unset;

  @media (max-width: 500px) {
    font-size: ${px(24)};
    line-height: ${px(29.52)};
  }
`

export const H1ArticleTitle = styled.h1`
  font-family: 'BentonSans';
  font-weight: bold;
  font-size: ${px(48)};
  line-height: ${px(56)};
  text-shadow: 0 2px 4px rgba(0,0,0,.5);
  margin: unset;
  @media (max-width: 500px) {
    font-size: ${px(24)};
    line-height: ${px(28.99992)};
  }
`

export const H3BoxTitle = styled.h3`
  font-family: 'BentonSans', sans-serif;
  font-weight: bold;
  font-size: ${px(24)};
  line-height: ${px(28)};
  text-align: center;

  @media (max-width: 500px) {
    font-size: ${px(18)};
  line-height: ${px(22.2)};
  }
`

export const H2Year = styled.h2`
  font-family: 'BentonSans', sans-serif;
  font-weight: bold;
  font-size: ${px(14)};
  line-height: ${px(16)};
  text-transform: uppercase;
  margin: unset;
`

// article inner text
export const ContentBlockText = styled.div`
  h3.mobile-heading {
    display: none;
    @media (max-width: 500px) {
      display: inline-block;
      font-family: 'BentonSans', sans-serif;
      font-weight: bold;
      font-size: ${px(24)};
      line-height: ${px(28.8)};
      margin-bottom: 15px;
    }
  }
  p {
    font-size: ${px(18)};
    line-height: ${px(23.94)};
    margin: unset;
    margin-bottom: ${px(16)};
    @media screen {
      font-size: ${px(14)};
      line-height: ${px(21)};
    }
  }
  b {
    font-weight: bold;
  }

  a {
    cursor: pointer;
  }

  a.wiki {
    display: block;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-left: auto;
    margin-bottom: 30px;
    background: url('${WikiImageUrl}') center center no-repeat rgba(0,0,0,.1);

    color: ${CssBlackText};
    text-decoration: none;

    transition-property: background;
    transition-duration: 0.3s;
    cursor: pointer;

    @media (max-width: 500px) {
      background: url('${WikiImageInvertedUrl}') center center no-repeat rgba(255,255,255,.1);
    }

  }
  a.wiki:hover {
    background-color: rgba(0,0,0,.15);
  }
  a.wiki::before {
    content: 'via';
    font-family: 'BentonSans', sans-serif;
    font-size: 14px;
    line-height: 19.25px;
    right: 32px;
    top: 14px;
    position: relative;
    cursor: pointer;
    @media (max-width: 500px) {
      color: white;
    }
  }

  a.button {
    display: inline-block;

    font-family: 'BentonSans', sans-serif;
    font-size: 14px;
    line-height: 28px;
    padding: 10px 20px;
    line-height: 28px;
    border: 1px solid #66AEC5;
    border-radius: 50px;
    text-decoration: none;

    color: #66AEC5;
    background: white;

    @media (max-width: 500px) {
      background: transparent;
    }

    transition-property: background, color;
    transition-duration: 0.3s;
    margin-top: ${px(30)};
    margin-bottom: ${px(16)};

    &:hover {
      background: rgba(102, 173, 197,.1);
      @media (max-width: 500px) {
        background: rgba(153, 82, 58,.1);
      }
    }
  }

  .the-team {
    font-size: 18px;
    line-height: 133%;
    padding-bottom: 1px;
    text-decoration: none;
    color: #66AEC5;
    transition-property: border-bottom-color, color;
    border-bottom: 1px solid transparent;
  }

  a.link {
    font-size: 18px;
    line-height: 133%;
    padding-bottom: 1px;
    text-decoration: none;
    color: #66AEC5;
    transition-property: border-bottom-color, color;
    border-bottom: 1px solid transparent;
  }

  a.link:hover {
    border-bottom: 1px solid #66AEC5;
  }

  .twitter-tweet {
    margin-left: auto;
    margin-right: auto;
  }

  .twitter-tweet + .twitter-tweet {
    margin-top: 16px !important;
    margin-bottom: 0px !important;
  }
`

export const WikiLink = styled.a `
    display: block;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-left: auto;
    background: url('${WikiImageUrl}') center center no-repeat rgba(0,0,0,.1);

    color: ${CssBlackText};
    text-decoration: none;

    transition-property: background;
    transition-duration: 0.3s;
    cursor: pointer;

    @media (max-width: 500px) {
      background: url('${WikiImageInvertedUrl}') center center no-repeat rgba(255,255,255,.1);
    }

  &hover {
    background-color: rgba(0,0,0,.15);
  }

  &::before {
    content: 'via';
    font-family: 'BentonSans', sans-serif;
    font-size: 14px;
    line-height: 19.25px;
    right: 32px;
    top: 14px;
    position: relative;
    cursor: pointer;
    @media (max-width: 500px) {
      color: white;
    }
  }
`
